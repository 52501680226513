
import { defineComponent } from "vue-demi";
import CommonTable from '@/components/common/table/index.vue';
import { USER_ORDER_TABLE_OPTIONS } from "@/store/common/table";

export default defineComponent({
  name: 'ProfileOrderMain',
  data() {
    return {
      options: USER_ORDER_TABLE_OPTIONS()
    }
  },
  components: {
    CommonTable
  }
})
